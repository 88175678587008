import PocketBase from 'pocketbase'
const masterPassword = 'gebetswoche2025mastercode@gebet-fuer-hohenlohe.de'

const pb = new PocketBase('https://backend.gebetskalender.dpantle.de')

// Get calendar
const getCalendar = async (calendarUrl) => {
  const records = await pb.collection('calendar').getFullList({
    filter: '(url="' + calendarUrl + '")',
    expand: 'categories,days,days.events_via_day,days.events_via_day.category'
  })

  const calendar = records[0]

  calendar.start = calendar.start.replace(' ', 'T')
  calendar.end = calendar.end.replace(' ', 'T')
  for (let i = 0; i < calendar.expand.days.length; i++) {
    calendar.expand.days[i].start = calendar.expand.days[i].start.replace(' ', 'T')
    calendar.expand.days[i].end = calendar.expand.days[i].end.replace(' ', 'T')

    if (!calendar.expand.days[i].expand) continue

    calendar.expand.days[i].expand.events = calendar.expand.days[i].expand.events_via_day

    for (let j = 0; j < calendar.expand.days[i].expand.events.length; j++) {
      calendar.expand.days[i].expand.events[j].start = calendar.expand.days[i].expand.events[
        j
      ].start.replace(' ', 'T')
      calendar.expand.days[i].expand.events[j].end = calendar.expand.days[i].expand.events[
        j
      ].end.replace(' ', 'T')
    }
  }

  return calendar
}

// Create new events
const createEvents = async (events) => {
  const records = []

  await events.forEach(async (event) => {
    records.push(
      await pb
        .collection('events')
        .create({ ...event, category: event.category.id }, { requestKey: null })
    )
  })

  return records
}

const deleteEvent = async (eventId, mail) => {
  const event = await pb.collection('events').getOne(eventId)

  if (!((event && event.mail === mail) || (masterPassword !== '' && mail === masterPassword)))
    throw new Error('E-Mail-Adresse stimmt nicht überein.')

  const response = await pb.collection('events').delete(eventId)
  return response
}

const apiService = {
  getCalendar,
  createEvents,
  deleteEvent
}

export default apiService
