import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Calendar from './pages/Calendar'
import Dashboard from './pages/Dashboard'
import Impressum from './pages/Impressum'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Navigate to='/bundestagswahl-2025' replace />} />
          <Route path='/:id' element={<Dashboard />} />
          <Route path='/:id/kalender' element={<Calendar />} />
          <Route path='/impressum' element={<Impressum />} />
        </Routes>
      </Router>

      <ToastContainer hideProgressBar={true} />
    </>
  )
}

export default App
