import React, { useLayoutEffect, useState } from 'react'
import { FaBars, FaRegCalendarAlt, FaRegMap, FaWindowClose } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import HeaderNavLink from './HeaderNavLink'

function Header() {
  const [navigationBar, setNavigationBar] = useState(false)
  const { calendar } = useSelector((state) => state.calendar)

  const location = useLocation()

  const closeNavigationBar = () => {
    setNavigationBar(false)
  }

  useLayoutEffect(() => {
    const onResize = () => {
      if (!navigationBar && window.innerWidth > 1024) {
        setNavigationBar(true)
      }
    }

    onResize()
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [navigationBar])

  return (
    <header className='z-40 fixed top-0 left-0 right-0 w-full bg-gray-300 h-15 uppercase print:hidden'>
      <nav className='flex justify-between items-center max-w-[1240px] mx-auto py-2 px-4'>
        <div className='font-bold w-full p-2 text-xl'>
          {calendar.name ? (
            <Link
              to={location.pathname.substring(0, location.pathname.lastIndexOf('/'))}
              onClick={closeNavigationBar}>
              {calendar.name}
            </Link>
          ) : (
            <Link to='/' onClick={closeNavigationBar}>
              Gebetskalender
            </Link>
          )}
        </div>

        {location.pathname !== '/' && location.pathname !== '/impressum' ? (
          <>
            <div
              onClick={() => setNavigationBar(!navigationBar)}
              className='block cursor-pointer lg:hidden'>
              {navigationBar ? <FaWindowClose size={20} /> : <FaBars size={20} />}
            </div>
            <div
              className={
                navigationBar
                  ? 'block fixed left-0 top-14 w-full h-full ease-in-out duration-200 lg:relative lg:top-0'
                  : 'fixed left-[-100%]'
              }>
              <div className='flex flex-col p-4 bg-gray-300 h-full lg:flex-row lg:p-0 lg:gap-2'>
                <HeaderNavLink to='/bundestagswahl-2025/kalender' title='Kalender' icon={<FaRegCalendarAlt />} />
                <HeaderNavLink
                  href='https://goo.gl/maps/HyaHjrEi2Ry8pYzr9'
                  title='Navigieren'
                  icon={<FaRegMap />}
                />
                <HeaderNavLink
                  mail='mailto:info@gebet-fuer-hohenlohe.de?subject=Frage zur 24/7-Gebetswoche 2025'
                  title='Kontakt'
                  icon={<FiMail />}
                />
              </div>
            </div>
          </>
        ) : (
          <HeaderNavLink to='/impressum' title='Impressum' icon={<FaRegCalendarAlt />} />
        )}
      </nav>
    </header>
  )
}

export default Header
